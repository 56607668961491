<script>
import icons from '@/assets/img/icons.svg';

export default {
  props: {
    name: String,
  },
  computed: {
    href() {
      return `${icons}#${this.name}`;
    },
  },
};
</script>

<template>
  <svg v-on="$listeners">
    <use v-bind:xlink:href="href"></use>
  </svg>
</template>

<style lang="postcss" scoped>
svg {
  color: inherit;
  display: inline-block;
  fill: currentColor;
  vertical-align: middle;
  size: inherit;
}
</style>
