var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expenses" },
    [
      this.noRecord
        ? _c("div", [
            _c(
              "a",
              {
                staticClass: "expenses__back",
                on: {
                  click: () => {
                    this.$router.push({ name: "expenses" })
                    _vm.idContaCartaoDebitado = null
                  },
                },
              },
              [
                _c("v-icon", {
                  staticClass: "expenses__back__icon",
                  attrs: { name: "back" },
                }),
                _vm._v("\n      Voltar\n    "),
              ],
              1
            ),
            _c("p", { staticClass: "expenses__text" }, [
              _vm._v("Não há registros. Tente novamente."),
            ]),
          ])
        : _c("v-expenses-table", { attrs: { expenses: _vm.expenses } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }