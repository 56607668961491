<script>
import Swal from 'sweetalert2';
import VExpensesTable from '../components/ExpensesTable.vue';
import VIcon from '../components/Icon.vue';

export default {
  components: {
    VExpensesTable,
    VIcon
  },
  data() {
    return {
      idContaCartaoExtrato: null,
      expenses: [],
      startDate: '',
      endDate: '',
      noRecord: false
    }
  },
  mounted() {
    this.getExpenses();
  },
  beforeMount() {
    this.idContaCartaoExtrato = this.$route.query.c;
    this.startDate = this.$route.query.start;
    this.endDate = this.$route.query.end
  },
  methods: {
    getExpenses() {
      this.$store.commit('pageLoading', true);
      this.$http.get(`/cards/extract/${this.idContaCartaoExtrato}?dataFim=${this.endDate}&dataInicio=${this.startDate}`)
        .then((res) => {
          this.expenses = res.data.content;

          if (!this.expenses.length) {
            this.noRecord = true
          }
          // let i;
          // for (i = 0; i < this.expenses.length; i += 1) {
          //   this.expenses[i].valorBRL = this.expenses[i].valorBRL.toLocaleString('en-US', { minimumFractionDigits: 2 }).split('.')
          //   if (this.expenses[i].flagCredito !== 0 && this.expenses[i].idTipoTransacaoNaoProcessada === null) {
          //     this.expenses.splice(i, 1)
          //   }
          // }
        })
        .catch(() => {
          this.noRecord = true
        })
        .then(() => this.$store.commit('pageLoading', false));
    },
    showAlert() {
      Swal.fire({
        title: 'OnlyPay Informa',
        html: '<p style="text-align: left; font-size: 16px; margin-top:50px;margin-bottom:50px;">Estamos com instabilidade em nosso sistema.<br>Nosso time está trabalhando para resolução o mais breve possível. <br>Pedimos desculpas pelo transtorno. <br><br>Pedimos que direcione sua solicitações e dúvidas para faleconosco@onlypay.com.br.<p>',
        type: 'info',
        confirmButtonText: 'Ok'
      })
    }
  }
};
</script>

<template>
  <div class="expenses">
    <div v-if="this.noRecord">
      <a @click="()=>{ this.$router.push({ name: 'expenses' }); idContaCartaoDebitado = null; }"
        class="expenses__back">
        <v-icon class="expenses__back__icon" name="back" />
        Voltar
      </a>
      <p class="expenses__text">Não há registros. Tente novamente.</p>
    </div>
    <v-expenses-table :expenses="expenses" v-else />
  </div>
</template>

<style lang="postcss" scoped>
.expenses {
  .table-row {
    font-size: 12px!important;
  }
}
.expenses {
  &__back{
    margin: 10px;
    &__icon{
      size: 1.5rem;
    }
  }
  &__text{
    margin: 10px;
  }
}
</style>
